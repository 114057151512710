import React, { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import { collection, onSnapshot, doc, deleteDoc, query, where, orderBy, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Tab, Table, Button, Alert } from 'react-bootstrap';
import ChatPanel from '../components/ChatPanel';
import Profile from '../components/Profile';
import PricingPage from '../components/PricingPage';
import { useTranslation } from 'react-i18next';
const Account = () => {
  const { user, logOut, userData } = UserAuth();
  const { t } = useTranslation();
  const [userAds, setUserAds] = useState([]);
  const [messagesData, setMessagesData] = useState([]);
  const [isChatPanelOpen, setIsChatPanelOpen] = useState(false);

  const [showSendMessagePanel, setShowSendMessagePanel] = useState(false);
  const [selectedPet, setSelectedPet] = useState(null); // Store the selected pet data here

  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

  useEffect(() => {
    // Function to check if the user has an active subscription
    async function checkSubscriptionStatus() {
      try {
        // Get the user's token (assuming you have a way to access the user object)
        const token = await user.getIdToken();

        // Include user's email in the payload
        const payload = {
          email: userData.email, // Assuming userData.email is available
        };


        // Make the API request to check the subscription status
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };

        const response = await fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/private/subscription', {
          method: 'POST', // or 'GET' if your endpoint expects a GET request
          headers: headers,
          body: JSON.stringify(payload, payload), // Include the email in the payload
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok (Status: ${response.status})`);
        }

        const data = await response.json();

        console.log(data)

        // Check if the response indicates an active subscription
        if (data && data.message === "Customer has an active subscription") {
          setHasActiveSubscription(true);
        } else {
          setHasActiveSubscription(false);
        }
      } catch (error) {
        console.error('Error checking subscription status:', error);
      }
    }

    // Call the function to check subscription status when the component mounts
    checkSubscriptionStatus();
  }, [user, userData.email]);



  // Function to handle when the "Send message" button is clicked
  const handleSendMessageClick = (item) => {
    const selectedPet = {
      id: item.advert_Id,
      author_Id: item.advert_owner_Id,
      title: item.title,
      chatChannelDocId: item.id
    }
    setSelectedPet(selectedPet);
    setShowSendMessagePanel(true);
  };

  // Function to close the SendMessagePanel
  const handleCloseSendMessagePanel = () => {
    setSelectedPet(null);
    setShowSendMessagePanel(false);
  };



  useEffect(() => {
    // Create a reference to the Firestore collection for ads
    const messagesCollectionRef = collection(db, 'messages');
    // Use onSnapshot to listen for real-time updates to the ads collection
    setMessagesData([]);
    const unsubscribe = onSnapshot(
      query(
        messagesCollectionRef,
        where('users', 'array-contains', user.uid),
        where('status', '==', true), // Ad
        orderBy('createDate', 'desc')
      ), (snapshot) => {
        const adsData = [];
        snapshot.forEach((doc) => {
          const data = doc.data();

          messagesData.push({
            id: doc.id,
            users: data.users,
            createDate: data.createDate,
            image: data.image,
            title: data.title,
            status: data.status,
            advert_Id: data.advert_Id,
            new_message_advert_owner: data.new_message_advert_owner,
            new_message_chat_owner: data.new_message_chat_owner,
            chat_owner_Id: data.chat_owner_id,
            chat_owner_name: data.chat_owner_name,
            advert_owner_Id: data.advert_owner_Id,
            advert_owner_name: data.advert_owner_name,

          });
        });
        setMessagesData(messagesData);
        // console.log(messagesData)
      }
    );

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [user.uid]);

  const adsCollectionRef = collection(db, 'adverts');
  useEffect(() => {
    // Create a reference to the Firestore collection for ads
    const adsCollectionRef = collection(db, 'adverts');

    // Use onSnapshot to listen for real-time updates to the ads collection
    const unsubscribe = onSnapshot(
      query(adsCollectionRef, where('author_Id', '==', user.uid), orderBy('create_date', 'desc')),
      (snapshot) => {
        const adsData = [];
        snapshot.forEach((doc) => {
          const adData = doc.data();
          if (adData.advertStatus === 0 || adData.advertStatus === 1 || adData.advertStatus === -1)

            adsData.push({
              id: doc.id,
              title: adData.title,
              images: adData.images,
              status: adData.advertStatus,
              create_date: adData.create_date,
              pet_type: adData.pet_type,
              pet_breed: adData.pet_breed,
              asking_price: adData.asking_price,
              description: adData.description,

              // Add other fields as needed
            });
        });
        setUserAds(adsData);
      }
    );

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [user.uid]);



  const handleDeleteAd = async (adId) => {
    try {
      // Create a reference to the Firestore document for the ad
      const adRef = doc(db, 'adverts', adId);

      // Update the advertStatus to 2
      await updateDoc(adRef, { advertStatus: 2 });

      // Handle any additional cleanup or UI changes
      // ...

      console.log('Ad deleted successfully.');
    } catch (error) {
      console.error('Error deleting ad: ', error);
    }
  };


  return (
    <Container>

      {showSendMessagePanel && (
        <ChatPanel
          selectedPet={selectedPet}
          onClose={handleCloseSendMessagePanel}
        />
      )}
      <div className="mt-4">
        <h2 className="mt-3 mb-3 p-4">Account</h2>
        <Tab.Container id="account-tabs" defaultActiveKey="myads">
          <Nav variant="tabs">



            <Nav.Item>
              <Nav.Link eventKey="myads">My Ads</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="messages">Messages</Nav.Link>
            </Nav.Item>



            <Nav.Item>
              <Nav.Link eventKey="profile">Profile</Nav.Link>

            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="myads">
              <div className='mt-4 text-right'>
                <Link to='/createadvert' className="btn btn-outline-success text-dark my-2 my-sm-0 btn-xl mr-2 mt-4">
                  <i className="fas fa-plus"></i> Post Free Ad
                </Link>
              </div>
              <Table striped className='mt-4'>
                <thead>
                  <tr>

                    <th>Craete Date</th>
                    <th>Status</th>
                    <th>Title</th>

                    <th>Pet Type</th>
                    <th>Rehome Fee</th>
                    <th>Images</th>
                    <th>Description</th>
                    <th>Action</th>

                    {/* Add other table headers as needed */}
                  </tr>
                </thead>
                <tbody>
                  {userAds.map((ad) => (
                    <tr key={ad.id}>


                      <td>
                        {new Date(ad.create_date).toLocaleDateString(undefined, {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </td>
                      <td>
                        {ad.status === 0 && (
                          <span className="badge badge-pill badge-warning">Under Review</span>
                        )}
                        {ad.status === 1 && (
                          <span className="badge badge-pill badge-success">
                            Published <i className="fas fa-check-circle"></i>
                          </span>
                        )}

                        {ad.status === -1 && (
                          <span className="badge badge-pill badge-danger">
                            Rejected <i className="fas fa-ban"></i>
                          </span>
                        )}

                      </td>

                      <td>{ad.title}</td>
                      <td>
                        {ad.pet_type}
                        {ad.pet_breed}
                      </td>
                      <td>
                        {ad.asking_price}
                      </td>
                      <td>

                        {ad.images.main_image ? (
                          <img src={ad.images.main_image} alt="Main Image" className="img-thumbnail" width="100" />
                        ) : (
                          <span>Main image missing (Ad will not be published)</span>
                        )}

                        {ad.images.image_1 && (
                          <img src={ad.images.image_1} alt="Image 1" className="img-thumbnail" width="100" />
                        )}

                        {ad.images.image_2 && (
                          <img src={ad.images.image_2} alt="Image 2" className="img-thumbnail" width="100" />
                        )}

                      </td>
                      <td>{ad.description}</td>

                      <td>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDeleteAd(ad.id)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </td>



                      {/* Add other table cells as needed */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab.Pane>

            <Tab.Pane eventKey="messages">

              <>
                <Container className='mt-4'>


                  {!hasActiveSubscription ? (
                    <>
                      <Alert variant="primary">
                        We rely on users' support to keep services running. To continue using our service, please subscribe now.
                      </Alert>
                      <PricingPage />
                    </>
                  ) :
                    <Table striped bordered hover className='mt-4'>
                      <thead>
                        <tr>
                          {/* <th>Action</th> */}
                          <th>Pet </th>
                          <th>Ad Title</th>
                          <th>Status</th>
                          <th>From</th>
                          <th>Chat</th>
                        </tr>
                      </thead>
                      <tbody>
                        {messagesData.map((item) => (
                          <tr key={item.id}>


                            <td>

                              {item.image ? (
                                <img src={item.image} alt="Main Image" className="img-thumbnail" width="100" />
                              ) : null}


                            </td>

                            <td>{item.title}</td>




                            <td>
                              {item.status && (
                                <span className="badge badge-pill badge-success">Active</span>
                              )}


                              {!item.status && (
                                <span className="badge badge-pill badge-danger">
                                  Blocked <i className="fas fa-ban"></i>
                                </span>
                              )}

                            </td>

                            <td>
                              {item.advert_owner_Id == user.uid ? item.advert_owner_name : item.chat_owner_name}

                              {/* {item.id} */}
                            </td>


                            <td>
                              <Button
                                className="btn btn-sm"
                                variant="success"
                                onClick={() => handleSendMessageClick(item)}
                              >
                                <i class="fa fa-comment" aria-hidden="true"></i> Open Chat



                              </Button>
                            </td>
                            {/* Add other table cells as needed */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>}
                </Container>
              </>
            </Tab.Pane>


            <Tab.Pane eventKey="profile">

              <>
                <Container className='mt-4'>
                  <Profile />
                </Container>
              </>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Container>
  );
};

export default Account;
