import React from 'react';
import { UserAuth } from '../context/AuthContext';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';
import { Link, useNavigate, useParams } from 'react-router-dom';

const Navigation = () => {
    const { user, logOut } = UserAuth();
    const { t } = useTranslation();
    const { lang } = useParams(); // Get the current language parameter
    const navigate = useNavigate();

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    };

    const LanguageSwitcher = () => {
        const { i18n } = useTranslation();

        const changeLanguage = (newLang) => {
            if (newLang !== i18n.language) {
                i18n.changeLanguage(newLang);
                navigate(`/${newLang}`);
            }
        };

        return (
            <div style={{ display: 'flex', gap: '10px' }}>
                <div onClick={() => changeLanguage('en')} style={{ cursor: 'pointer' }}>
                    <Flag code="US" alt="English" width="30" />
                </div>
                <div onClick={() => changeLanguage('de')} style={{ cursor: 'pointer' }}>
                    <Flag code="DE" alt="Deutsch" width="30" />
                </div>
                <div onClick={() => changeLanguage('es')} style={{ cursor: 'pointer' }}>
                    <Flag code="ES" alt="Spanish" width="30" />
                </div>
                <div onClick={() => changeLanguage('fr')} style={{ cursor: 'pointer' }}>
                    <Flag code="FR" alt="French" width="30" />
                </div>
                <div onClick={() => changeLanguage('ar')} style={{ cursor: 'pointer' }}>
                    <Flag code="AE" alt="Arabic" width="30" />
                </div>
                <div onClick={() => changeLanguage('nl')} style={{ cursor: 'pointer' }}>
                    <Flag code="NL" alt="Dutch" width="30" />
                </div>
                <div onClick={() => changeLanguage('it')} style={{ cursor: 'pointer' }}>
                    <Flag code="IT" alt="Italian" width="30" />
                </div>
                <div onClick={() => changeLanguage('pt')} style={{ cursor: 'pointer' }}>
                    <Flag code="PT" alt="Italian" width="30" />
                </div>
                <div onClick={() => changeLanguage('zh')} style={{ cursor: 'pointer' }}>
                    <Flag code="CN" alt="Chinese" width="30" />
                </div>
            </div>
        );
    };

    return (
        <Navbar sticky="top" className="bg-white" expand="lg" variant="light">
            <Container fluid>
                <Navbar.Brand as={Link} to={`/${lang || 'en'}`} className="d-flex align-items-center">
                    <img src="/android-chrome-192x192.png" width="50" height="50" className="d-inline-block align-top mr-1" alt="" />
                    <span>
                        {t('global.app_name')}
                        <small className='d-none d-sm-block' style={{ fontSize: 12 }}> {t('global.app_slogan')}</small>
                    </span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to={`/${lang || 'en'}`}><i className="fa fa-home" aria-hidden="true"></i> {t('global.nav_home')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/findpets`}><i className="fa fa-search" aria-hidden="true"></i> {t('global.nav_find')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/breed-detector`}><i className="fa fa-magic" aria-hidden="true"></i> {t('global.nav_breed')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/pet-advisor`}><i className="fa fa-user-md" aria-hidden="true"></i> {t('global.nav_advisor')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/videos`}><i className="fa fa-play" aria-hidden="true"></i> {t('global.nav_video')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang}/blog`}> <i className="fa fa-blog" aria-hidden="true"></i> {t('global.nav_blog')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang}/faq`}> <i className="fa fa-blog" aria-hidden="true"></i> FAQ</Nav.Link>


                        <LanguageSwitcher />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;



