import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const BlogList = () => {
    const { t } = useTranslation();
    const { lang } = useParams();
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        // Fetch posts with embedded media
        fetch(
            'https://blog.petshome.app/wp-json/wp/v2/faqs?_fields=id,title,excerpt,slug,_links&per_page=100&_embed'
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setPosts(data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div className="container blog-list">
            <h1 className="my-4">{t('Latest Blog Posts')}</h1>
            {posts.length === 0 ? (
                <p>{t('Loading posts...')}</p>
            ) : (
                <div className="row">
                    {posts.map((post) => {
                        const featuredMedia =
                            post._embedded &&
                            post._embedded['wp:featuredmedia'] &&
                            post._embedded['wp:featuredmedia'][0];

                        let imageUrl = null;
                        let srcSet = null;

                        if (featuredMedia) {
                            const sizes = featuredMedia.media_details.sizes;

                            // Choose appropriate image size
                            if (sizes.medium_large) {
                                imageUrl = sizes.medium_large.source_url;
                            } else if (sizes.medium) {
                                imageUrl = sizes.medium.source_url;
                            } else if (sizes.thumbnail) {
                                imageUrl = sizes.thumbnail.source_url;
                            } else {
                                imageUrl = featuredMedia.source_url;
                            }

                            // Construct srcSet for responsive images
                            srcSet = Object.values(sizes)
                                .map((size) => `${size.source_url} ${size.width}w`)
                                .join(', ');
                        }

                        return (
                            <div key={post.id} className="col-md-4 col-sm-6 mb-4">
                                <div className="card h-100">
                                    {imageUrl && (
                                        <Link to={`/${lang}/${post.slug}`}>
                                            <img
                                                src={imageUrl}
                                                srcSet={srcSet}
                                                sizes="(max-width: 600px) 100vw, 50vw"
                                                alt={post.title.rendered}
                                                className="card-img-top"
                                                loading="lazy"
                                            />
                                        </Link>
                                    )}
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <Link
                                                to={`/${lang}/${post.slug}?type=faqs`}
                                                dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                                            />
                                        </h5>
                                        {/* <div
                                            className="card-text"
                                            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default BlogList;
