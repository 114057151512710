import React, { useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Alert, Form, Button } from 'react-bootstrap';
import PhoneAuth from '../context/PhoneAuth';
import { GoogleButton } from 'react-google-button';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useTranslation } from 'react-i18next';

const Signin = () => {
  const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();
  const [signInError, setSignInError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (user != null) {
      navigate('/account');
    }
  }, [user, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      setSignInError(error.message);
    }
  };


  const handleSubmit = (formType) => async (e) => {

  };



  return (
    <div className="text-dark justify-content-center align-items-center " >

      <Container className='mt-4 '>

        <Row>
          {/* Sign-in Form */}
          <Col md={12} className=' p-4'>

            <p className="">Experience a more convenient way to connect with potential adopters. Our mobile app offers features like instant notifications when someone contacts you, easy photo uploads, and the ability to share videos of your pet's charm.</p>
            <p className='text-center font-weight-bold text-uppercase'>Download Our Free App Now</p>
            <div className="d-flex justify-content-center">
              <a style={{ width: '130px', height: 'auto' }} href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3"><img src="/google-play-badge.svg" alt="Google Play" /></a>
              <a style={{ width: '130px', height: 'auto' }} href="https://apps.apple.com/us/app/pets-home-app/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src="/app-store-badge.svg" alt="App Store" /></a>
            </div>

            <div className="d-flex justify-content-center">
              <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
              <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
            </div>
            <p className="text-center mt-3">Scan the QR codes below for quick access:</p>


          </Col>
          {/* <Col md={12} className='bg-light mt-4 p-4 rounded'>

            <GoogleButton className="mt-4 mb-4" style={{ display: 'block', margin: 'auto' }} onClick={handleGoogleSignIn} />
          </Col> */}
          {/* Sign-up Form */}
          {/* Sign-in Form */}
          <Col md={12} className='bg-light mt-4 p-4 rounded'>
            {/* <PhoneAuth /> */}

          </Col>
          {/* Sign-up Form */}



        </Row>
      </Container>
    </div>
  );
};

export default Signin;
