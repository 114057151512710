import React, { useState, useEffect } from 'react';

import { Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navigation from './components/Navbar';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';
import Account from './pages/Account';
import FindPets from './pages/FindPets';
import Home from './pages/Home';
import Signin from './pages/Signin';
import CreateAdvert from './components/CreateAdvert';
import BlogList from './pages/BlogList';
import FaqList from './pages/FaqList';
import BlogPost from './pages/BlogPost';
import BreedDetector from './pages/BreedDetector';
import PetAdvisor from './pages/PetAdvisor';
import VideoPage from './pages/videos';
import Termsofuse from './pages/Termsofuse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Footer from './components/Footer'; // Import the Footer component

import { auth } from './firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/minty/bootstrap.min.css';
import './i18n';
import './App.css';



import ReactGA from "react-ga4";
ReactGA.initialize("TBHT1X4QEV");
function App() {
  const { i18n } = useTranslation(); // Destructure i18n
  return (
    <AuthContextProvider>
      <Routes>
        {/* Catch-all route for language-specific paths */}
        <Route path="/terms-of-use" element={<Termsofuse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/:lang/*" element={<MainLayout />} />
        {/* Redirect root to the default language */}
        <Route path="/" element={<Navigate to={`/${i18n.language}`} />} />
        {/* Redirect unmatched paths */}
        <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
      </Routes>
    </AuthContextProvider>
  );
}

function MainLayout() {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang);
    } else if (!lang) {
      navigate(`/${i18n.language}`);
    }
  }, [lang, i18n, navigate]);

  return (
    <>
      <Navigation />
      <div className="container-fluid">
        <main role="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="signin" element={<Signin />} />
            <Route path="account" element={<Protected><Account /></Protected>} />
            <Route path="findpets" element={<FindPets />} />
            <Route path="pet-advisor" element={<PetAdvisor />} />
            <Route path="breed-detector" element={<BreedDetector />} />
            <Route path="createadvert" element={<CreateAdvert />} />
            <Route path="videos" element={<VideoPage />} />

            {/* Blog Routes */}
            <Route path="blog" element={<BlogList />} />
            <Route path=":slug" element={<BlogPost />} />


            {/* Blog Routes */}
            <Route path="faq" element={<FaqList />} />


            <Route path="terms-of-use" element={<Termsofuse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            {/* Redirect unmatched routes to home within the language */}
            {/* Redirect root to the default language */}
            <Route path="/" element={<Navigate to={`/${i18n.language}`} />} />

            {/* Redirect unmatched paths */}
            <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
            <Route path="*" element={<Navigate to={`/${lang}`} />} />
          </Routes>
        </main>
      </div>
      <Footer /> {/* Include the Footer here */}
    </>
  );
}

export default App;