import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faYoutube, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';





function Home() {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  return (
    <>

      <Helmet>
        <title>{t('home.meta_title')}</title>
        <meta name="description" content={t('home.meta_description')} />
        <meta name="keywords" content={t('home.meta_keywords')} />
      </Helmet>
      <div className="text-dark" style={{ minHeight: '100vh' }}>
        {/* Breadcrumb Navigation */}
        {/* <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Rehome / Sell Pets</li>
        </ol>
      </nav> */}

        <section className="text-center">
          <div className="jumbotron text-dark" style={{ paddingBottom: '0', backgroundColor: 'white' }}>
            <Container>
              <Row>
                <Col md={12} className="p-2 text-center">
                  <h1 className="display-8 text-dark">
                    {t('home.h1')}
                  </h1>
                  <p className="lead">
                    {t('home.subheading')}
                  </p>
                  <h5 className="text-center font-weight-bold">{t('global.h5_dwonload')}</h5>
                  <div className="d-flex justify-content-center">
                    <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
                      <img style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
                    </a>
                    <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
                      <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
                    </a>
                  </div>
                  <small>  {t('global.rated')} </small>
                  <div>
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                  </div>

                  <img
                    style={{ maxWidth: '300px', height: 'auto' }}
                    className="img-fluid rounded mb-4"
                    src="./assets/petshomeapp.png"
                    alt="Sell Your Pet Online for Free with Pets Home App - Dogs, Cats, Birds"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </section>




        <Container className="p-4">
          <Row>

            <Col md={6} className="text-center  d-md-block">
              <h3>{t('home.h3_sell')} </h3>

              <p className="lead">
                {t('home.h3_sell_p')}
              </p>
              <img
                style={{ maxWidth: '300px', height: 'auto' }}
                className="img-fluid rounded mb-4 center"
                src="./assets/petshome-post-free-ad.png"
                alt="Sell Your Pet Online for Free with Pets Home App - Dogs, Cats, Birds"
              />



            </Col>
            <Col md={6} className="text-center  d-md-block">
              <h3>{t('home.h3_breed')}</h3>
              <p className="lead">
                {t('home.h3_breed_p')}
              </p>

              <img
                style={{ maxWidth: '300px', height: 'auto' }}
                className="img-fluid rounded mb-4 center"
                src="./assets/petshome-breed-ai.png"
                alt="Sell Your Pet Online for Free with Pets Home App - Dogs, Cats, Birds"
              />

            </Col>
            <Col md={6} className="text-center d-md-block">
              <h3>{t('home.h3_social')}</h3>
              <p className="lead">
                {t('home.h3_social_p')}
              </p>
              <img
                style={{ maxWidth: '300px', height: 'auto' }}
                className="img-fluid rounded mb-4 center"
                src="./assets/petshome-social.png"
                alt="Sell Your Pet Online for Free with Pets Home App - Dogs, Cats, Birds"
              />

            </Col>

            <Col md={6} className="text-center  d-md-block">
              <h3>{t('home.h3_advisor')}</h3>
              <p className="lead">
                {t('home.h3_advisor_p')}
              </p>
              <img
                style={{ maxWidth: '300px', height: 'auto' }}
                className="img-fluid rounded mb-4 center"
                src="./assets/petshome-pet-advisor.png"
                alt="Sell Your Pet Online for Free with Pets Home App - Dogs, Cats, Birds"
              />

            </Col>
            <Col md={6} className="text-center d-md-block">

              <h3>{t('home.h3_video')}</h3>
              <p className="lead">
                {t('home.h3_video_p')}
              </p>

              <img
                style={{ maxWidth: '300px', height: 'auto' }}
                className="img-fluid rounded mb-4 center"
                src="./assets/petshome-video.png"
                alt="Sell Your Pet Online for Free with Pets Home App - Dogs, Cats, Birds"
              />
            </Col>
          </Row>
        </Container>









        {/* Social Sharing Buttons */}
        <div className="social-sharing text-center my-4">
          <p>{t('global.share_p')}</p>
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.petshome.app/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="2x" className="mx-2" />
          </a>
          <a href="https://twitter.com/intent/tweet?url=https://www.petshome.app/&text=Sell%20Your%20Pet%20Online%20for%20Free%20with%20Pets%20Home%20App" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} size="2x" className="mx-2" />
          </a>
          <a href="https://www.instagram.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" className="mx-2" />
          </a>
          <a href="https://www.pinterest.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faPinterest} size="2x" className="mx-2" />
          </a>
          <a href="https://www.youtube.com/channel/UCTjh3nmz0cVGaX0MnHl6elA" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} size="2x" className="mx-2" />
          </a>
        </div>


      </div>
    </>
  );
}

export default Home;
