import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const stripHtml = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
};

const BlogPost = () => {
    const { t } = useTranslation();
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const location = useLocation();

    // Parse the query string to get the 'type' parameter
    const queryParams = new URLSearchParams(location.search);
    const postType = queryParams.get('type') || 'posts'; // Default to 'posts' if not specified

    useEffect(() => {
        // Fetch the post by slug
        fetch(
            `https://blog.petshome.app/wp-json/wp/v2/${postType}?slug=${slug}&_embed`
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    setPost(data[0]);
                } else {
                    setPost(null);
                }
            })
            .catch((error) => {
                console.error('Error fetching post:', error);
                setPost(null);
            });
    }, [slug, postType]);

    if (post === null) {
        return <p>{t('Loading post...')}</p>;
    } else if (!post) {
        return <p>{t('Post not found.')}</p>;
    } else {
        const featuredMedia =
            post._embedded &&
            post._embedded['wp:featuredmedia'] &&
            post._embedded['wp:featuredmedia'][0];

        let imageUrl = null;

        if (featuredMedia) {
            const sizes = featuredMedia.media_details.sizes;

            // Choose appropriate image size
            if (sizes.medium_large) {
                imageUrl = sizes.medium_large.source_url;
            } else if (sizes.medium) {
                imageUrl = sizes.medium.source_url;
            } else if (sizes.thumbnail) {
                imageUrl = sizes.thumbnail.source_url;
            } else {
                imageUrl = featuredMedia.source_url;
            }
        }

        const pageTitle = stripHtml(post.title.rendered) + ' | PetsHome';
        // Prepare meta description
        let metaDescription = '';

        if (post.excerpt && post.excerpt.rendered) {
            metaDescription = stripHtml(post.excerpt.rendered);
        } else if (post.content && post.content.rendered) {
            // Use a substring of the content as a fallback
            metaDescription = stripHtml(post.content.rendered).substring(0, 155);
        }
        return (
            <>
                <Helmet>
                    <link
                        rel="canonical"
                        href={`https://www.petshome.app${location.pathname}${location.search}`}
                    />
                    <title>{stripHtml(post.title.rendered)}</title>
                    <meta name="description" content={metaDescription} />
                    {/* Open Graph Meta Tags */}
                    <meta property="og:title" content={stripHtml(post.title.rendered)} />
                    <meta property="og:description" content={metaDescription} />
                    {imageUrl && <meta property="og:image" content={imageUrl} />}
                    <meta property="og:type" content="article" />
                    <meta
                        property="og:url"
                        content={`https://petshome.app${location.pathname}`}
                    />
                    {/* Twitter Card Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta
                        name="twitter:title"
                        content={stripHtml(post.title.rendered)}
                    />
                    <meta name="twitter:description" content={metaDescription} />
                    {imageUrl && <meta name="twitter:image" content={imageUrl} />}
                </Helmet>
                <div className="blog-post">
                    {imageUrl && (
                        <img src={imageUrl} alt={stripHtml(post.title.rendered)} />
                    )}
                    <h1 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                    />
                </div>
            </>
        );
    }
};

export default BlogPost;
