import { useContext, createContext, useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Your Firestore import
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../firebase'; // Your Firebase auth import

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});

  const googleSignIn = () => {
    // const provider = new GoogleAuthProvider();
    // signInWithPopup(auth, provider);
  };

  const logOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        // signOut(auth);
        // Set up a listener for the user's document
        const unsubscribeUser = onSnapshot(doc(db, 'users', currentUser.uid), (doc) => {
          if (doc.exists()) {
            setUserData(doc.data());
          } else {
            console.log('No user document found!');
          }
        });

        return () => unsubscribeUser(); // This is to unsubscribe from the user's document when the component unmounts
      } else {
        setUserData({});
      }
    });
    return () => {
      unsubscribeAuth();
    };
  }, []);

  // Value provided to the context consumers
  const value = {
    googleSignIn,
    logOut,
    user,
    userData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const UserAuth = () => useContext(AuthContext);
